<div class="property-item selectable-item clickable"
     [ngClass]="{ clickable: onClick.observers.length > 0 }">
      <ion-checkbox class="small"
                    *ngIf="selectable"
                    [ngClass]="{ checked: selected }"
                    [ngModel]="selected"
                    (ngModelChange)="itemToggled($event)"
                    color="primary-blue"
                    mode="ios">
      </ion-checkbox>
      <ion-item [attr.detail-push]="onClick.observers.length > 0 ? true : null"
                [ngClass]="{ hovered: hoveredProperty && hoveredProperty._id === property._id, 'has-image': isImageShown && property.blobImageData, 'for-sale': property.forSale }"

                (mouseover)="onMouseover.emit(property)"
                (mouseout)="onMouseout.emit(property)"
                (click)="onClick.emit(property)">
            <ion-icon name="business"
                      [ngClass]="{'action-circle': property.forSale}"
                      circle
                      material-icons
                      item-left></ion-icon>

            <ion-thumbnail *ngIf="isImageShown && property.blobImageData"
                           item-right
                           [ngClass]="{'property-item--action-border': property.forSale}">
                  <img [src]="property.blobImageData">
                  <div *ngIf="property.forSale && isPhotoBannerShown"
                       class="property-item--days-on-market-label">Listed {{property.daysOnMarket}} day(s)</div>
            </ion-thumbnail>

            <h2 class="property-item--dark-text">{{property.name}}</h2>
            <p>
                  {{property.addressString}}
                  <span *ngIf="isShownOnMap && (!property.location || (property.location.lat === 0 && property.location.lng === 0))"
                        class="property-mapping-error">
                        - Not mappable
                  </span>
            </p>

            <!--Contract specific fields-->
            <span *ngIf="selectedRecord && selectedRecord.contractPrice">{{ selectedRecord.contractPrice | currency:'USD':'symbol':'1.0-0'}}</span>
            <span *ngIf="selectedRecord && selectedRecord.contractPrice && property && property.squareFootage"> • </span>
            <span [innerHTML]="getPriceAndSqft(property)"></span>

            <!--Pursuit specific fields-->
            <span class="property-item--sellerPrice"
                  *ngIf="selectedRecord && selectedRecord.sellerPriceExpectation">{{selectedRecord.sellerPriceExpectation | currency:'USD':'symbol':'1.0-0'}}</span>
            <span *ngIf="selectedRecord && selectedRecord.sellerPriceExpectation && selectedRecord.brokerProposedPrice"> • </span>
            <span class="property-item--brokerPrice"
                  *ngIf="selectedRecord && selectedRecord.brokerProposedPrice">{{selectedRecord.brokerProposedPrice | currency:'USD':'symbol':'1.0-0'}}</span>
      </ion-item>
</div>