import { __extends } from "tslib";
var __assign = (this && this.__assign) || Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { debounceTime } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertController, ModalController, ToastController } from 'ionic-angular';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { AddressModel, ListingModel, OwnershipModel, PropertyModel } from '@apto/models';
import { AnalyticsService, ListingsService, OwnershipRelationsService, PropertiesService, TypesService } from '@apto/services';
import { AuthService, LogsService } from '@apto/ionic-lib/services';
import { AptoMapComponent, MarkerType, MarkerClassName, PropertyInfoWindow, PlaceInfoWindow } from '@apto/ng2-apto-map';
import { TOAST_DURATION } from '@apto/shared/constants';
import { ExportService, MetricsService } from '@apto/shared/services';
import { CallListMembersModal, PropertyDetailsModal, PropertyDetailsModalMode } from '@apto/pn/modals';
import { BaseListPage } from '@apto/pn/pages';
import { MappingCollection, MappingService } from '@apto/pn/services';
import { MappingDataManager } from './mapping-data-manager';
import './property-map.scss';
var PropertyMapPage = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(PropertyMapPage, _super);
    function PropertyMapPage(ref, mappingCollection, logsService, typesService, modalController, mappingDataManager, propertiesService, toastController, mappingService, metricsService, exportService, alertCtrl, storage, router, route, analyticsService, authService, listingsService, ownershipRelationsService) {
        var _this = _super.call(this) || this;
        _this.ref = ref;
        _this.mappingCollection = mappingCollection;
        _this.logsService = logsService;
        _this.typesService = typesService;
        _this.modalController = modalController;
        _this.mappingDataManager = mappingDataManager;
        _this.propertiesService = propertiesService;
        _this.toastController = toastController;
        _this.mappingService = mappingService;
        _this.metricsService = metricsService;
        _this.exportService = exportService;
        _this.alertCtrl = alertCtrl;
        _this.storage = storage;
        _this.router = router;
        _this.route = route;
        _this.analyticsService = analyticsService;
        _this.authService = authService;
        _this.listingsService = listingsService;
        _this.ownershipRelationsService = ownershipRelationsService;
        _this.compTypes = new Map();
        _this.debounceTime = 1000;
        _this.isInitializingMap = true;
        _this.listingTypes = new Map();
        _this.orderBy = 'name';
        _this.properties = [];
        _this.propertyTypes = {};
        _this.idToStringRecordMapping = {};
        _this.resultLimit = 500;
        _this.totalResults = 0;
        _this.picklistOptionsToRetrieve = ['class', 'market', 'primaryUse', 'status', 'submarket', 'tenancy'];
        _this.enableSearchSecondAttempt = true;
        _this.placeInfoWindowSubscriptions = [];
        _this.stopMapZoomPanEvents = false;
        _this.subscriptions = [];
        _this.updateWithMove = true;
        return _this;
    }
    PropertyMapPage.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var propertyId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.metricsService.start('Property Map Page');
                        propertyId = this.route.snapshot.paramMap.get('propertyId');
                        if (propertyId) {
                            this.metricsService.setRouteName('/property-map/:propertyId');
                        }
                        else {
                            this.metricsService.setRouteName('/property-map');
                        }
                        this.aptoMap.setLogger(this.logsService);
                        this.aptoMap.setAnalytics(this.analyticsService);
                        this.mappingDataManager.setLogger(this.logsService);
                        this.subscriptions.push(this.propertiesService.onDeleted.subscribe(function () { return _this.search(_this.aptoMap.getFilterPanelQueries()); }, this.logsService.error));
                        this.subscriptions.push(this.propertiesService.onUpserted.subscribe(function (data) {
                            return __awaiter(_this, void 0, void 0, function () {
                                var err_1;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            _a.trys.push([0, 2, , 3]);
                                            return [4 /*yield*/, this.mappingCollection.updateProperty(data.payload)];
                                        case 1:
                                            _a.sent();
                                            return [3 /*break*/, 3];
                                        case 2:
                                            err_1 = _a.sent();
                                            this.logsService.error(err_1);
                                            return [3 /*break*/, 3];
                                        case 3:
                                            // if data was modified, rerun search
                                            this.search(this.aptoMap.getFilterPanelQueries());
                                            return [2 /*return*/];
                                    }
                                });
                            });
                        }, this.logsService.error));
                        return [4 /*yield*/, this.checkMappingEnabled()];
                    case 1:
                        _a.sent();
                        this.metricsService.stop('Property Map Page');
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyMapPage.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.propertyId$ = this.route.paramMap.subscribe(function (paramMap) {
            return __awaiter(_this, void 0, void 0, function () {
                var propertyId, properties, listings, listingProps;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            propertyId = paramMap.get('propertyId');
                            if (!propertyId) {
                                return [2 /*return*/];
                            }
                            // if we have a property run a search for that property so the map centers on it
                            // also open up the property modal
                            this.router.navigate([], {
                                queryParams: {
                                    query: this.aptoMap.urlSafeQuery({ textbox: propertyId })
                                }
                            });
                            return [4 /*yield*/, this.propertiesService.in([propertyId])];
                        case 1:
                            properties = _a.sent();
                            return [4 /*yield*/, this.listingsService.forProperties([propertyId])];
                        case 2:
                            listings = _a.sent();
                            this.analyticsService.logEvent('Mapping View Property From Global Search');
                            if (properties.length > 0) {
                                listingProps = listings[0] ? {
                                    forSale: true,
                                    daysOnMarket: listings[0].daysOnMarket && listings[0].daysOnMarket > 0
                                        ? listings[0].daysOnMarket
                                        : null,
                                    listingId: listings[0]._id,
                                    internal: !(listings[0].type || '').includes('external'),
                                } : {};
                                this.viewProperty(__assign({}, properties[0], listingProps));
                            }
                            return [2 /*return*/];
                    }
                });
            });
        });
    };
    PropertyMapPage.prototype.ngOnDestroy = function () {
        this.propertyId$.unsubscribe();
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    PropertyMapPage.prototype.checkMappingEnabled = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var hasMappingFeaturesEnabled;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get(this.mappingService.LOCAL_STORAGE_KEY)];
                    case 1:
                        hasMappingFeaturesEnabled = _a.sent();
                        if (!hasMappingFeaturesEnabled)
                            return [3 /*break*/, 6];
                        if (!this.importPrompt)
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.importPrompt.dismiss()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!this.configurationPrompt)
                            return [3 /*break*/, 5];
                        return [4 /*yield*/, this.configurationPrompt.dismiss()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.initialize();
                        return [3 /*break*/, 10];
                    case 6:
                        if (!(hasMappingFeaturesEnabled === false))
                            return [3 /*break*/, 7];
                        this.router.navigate(['/properties']);
                        return [3 /*break*/, 10];
                    case 7:
                        if (!(hasMappingFeaturesEnabled === undefined ||
                            hasMappingFeaturesEnabled === null))
                            return [3 /*break*/, 10];
                        if (!!this.importPrompt)
                            return [3 /*break*/, 9];
                        this.configurationPrompt = this.alertCtrl.create({
                            title: 'Mapping Configuration',
                            message: 'We have released an enhanced mapping page for the properties view with expanded search ' +
                                'and filtering tools to help you effectively research and to easily build call lists. ' +
                                'Please wait one moment while we check your organization\'s configuration.',
                            enableBackdropDismiss: false,
                            cssClass: 'property-map',
                            buttons: ['OK']
                        });
                        return [4 /*yield*/, this.configurationPrompt.present()];
                    case 8:
                        _a.sent();
                        _a.label = 9;
                    case 9:
                        this.mappingService.checkEnabledMapping().then(function () {
                            return __awaiter(_this, void 0, void 0, function () {
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!(this.mappingService.mappingFeatures.hasOauthEnabled &&
                                                this.mappingService.mappingFeatures.hasFinishedImport))
                                                return [3 /*break*/, 5];
                                            if (!this.importPrompt)
                                                return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.importPrompt.dismiss()];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2:
                                            if (!this.configurationPrompt)
                                                return [3 /*break*/, 4];
                                            return [4 /*yield*/, this.configurationPrompt.dismiss()];
                                        case 3:
                                            _a.sent();
                                            _a.label = 4;
                                        case 4:
                                            this.initialize();
                                            return [3 /*break*/, 14];
                                        case 5:
                                            if (!(this.mappingService.mappingFeatures.hasOauthEnabled &&
                                                !this.mappingService.mappingFeatures.hasFinishedImport))
                                                return [3 /*break*/, 9];
                                            if (!!this.importPrompt)
                                                return [3 /*break*/, 8];
                                            this.importPrompt = this.alertCtrl.create({
                                                title: 'Mapping Import',
                                                message: 'We are currently importing your organization\'s properties into the new mapping experience. ' +
                                                    'Please wait a few minutes while we get this done. This message will disappear automatically ' +
                                                    'when complete.',
                                                enableBackdropDismiss: false,
                                                cssClass: 'property-map',
                                                buttons: ['OK']
                                            });
                                            if (!this.configurationPrompt)
                                                return [3 /*break*/, 6];
                                            setTimeout(function () {
                                                return __awaiter(_this, void 0, void 0, function () {
                                                    return __generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0: return [4 /*yield*/, this.configurationPrompt.dismiss()];
                                                            case 1:
                                                                _a.sent();
                                                                return [4 /*yield*/, this.importPrompt.present()];
                                                            case 2:
                                                                _a.sent();
                                                                return [2 /*return*/];
                                                        }
                                                    });
                                                });
                                            }, 5000);
                                            return [3 /*break*/, 8];
                                        case 6: return [4 /*yield*/, this.importPrompt.present()];
                                        case 7:
                                            _a.sent();
                                            _a.label = 8;
                                        case 8:
                                            setTimeout(this.checkMappingEnabled.bind(this), 6000);
                                            return [3 /*break*/, 14];
                                        case 9:
                                            this.failurePrompt = this.alertCtrl.create({
                                                title: 'Mapping Failed',
                                                message: 'Oh, it looks like we’re having some problems with our Apto Admin connection ' +
                                                    'when configuring the new experience. Click here to learn how to enable this feature ' +
                                                    'or contact support at <strong>support@apto.com</strong>.<br><br>' +
                                                    '<a target="_mapsupport" href="https://help.apto.com/hc/en-us/articles/360002882511-Getting-Started-Apto-Maps">https://help.apto.com/hc/en-us/articles/360002882511-Getting-Started-Apto-Maps</a> ' + // tslint:disable-line
                                                    '<br><br>Taking you back to the old property list page after pressing OK.',
                                                enableBackdropDismiss: false,
                                                buttons: ['OK'],
                                                cssClass: 'property-map'
                                            });
                                            this.failurePrompt.onDidDismiss(function () {
                                                _this.router.navigate(['/properties']);
                                                _this.storage.set(_this.mappingService.LOCAL_STORAGE_KEY, false);
                                            });
                                            if (!this.configurationPrompt)
                                                return [3 /*break*/, 12];
                                            return [4 /*yield*/, this.configurationPrompt.dismiss()];
                                        case 10:
                                            _a.sent();
                                            return [4 /*yield*/, this.failurePrompt.present()];
                                        case 11:
                                            _a.sent();
                                            return [3 /*break*/, 14];
                                        case 12: return [4 /*yield*/, this.failurePrompt.present()];
                                        case 13:
                                            _a.sent();
                                            _a.label = 14;
                                        case 14: return [2 /*return*/];
                                    }
                                });
                            });
                        });
                        _a.label = 10;
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    PropertyMapPage.prototype.setPicklistOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var allTypes, listings, propertyPicklistOptions, err_2, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Promise.all([
                                this.typesService.fetch(),
                                this.mappingDataManager.getListings(),
                                this.propertiesService.getPicklistOptions(this.picklistOptionsToRetrieve)
                            ])];
                    case 1:
                        _a = _b.sent(), allTypes = _a[0], listings = _a[1], propertyPicklistOptions = _a[2];
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _b.sent();
                        this.logsService.error(err_2);
                        return [3 /*break*/, 3];
                    case 3:
                        // get all property types
                        try {
                            Object.keys(allTypes.listingTypes).forEach(function (name) {
                                _this.listingTypes.set(allTypes.listingTypes[name], name);
                            });
                            Object.keys(allTypes.compTypes).forEach(function (name) {
                                _this.compTypes.set(allTypes.compTypes[name], name);
                            });
                            Object.keys(allTypes.propertyTypes).forEach(function (name) {
                                if (name.toLowerCase() !== 'space') {
                                    _this.propertyTypes[name] = allTypes.propertyTypes[name];
                                    _this.idToStringRecordMapping[allTypes.propertyTypes[name]] = name;
                                }
                            });
                            this.mappingCollection.recordTypeMapping = this.idToStringRecordMapping;
                        }
                        catch (err) {
                            this.logsService.error(err);
                        }
                        this.aptoMap.buildingClassOptions = this.buildMapSelectOptions(propertyPicklistOptions.class, 'Class');
                        this.aptoMap.primaryUseOptions = this.buildMapSelectOptions(propertyPicklistOptions.primaryUse, 'Use');
                        this.aptoMap.marketOptions = this.buildMapSelectOptions(propertyPicklistOptions.market, 'Market');
                        this.aptoMap.buildlingStatusOptions = this.buildMapSelectOptions(propertyPicklistOptions.status, 'Status');
                        this.aptoMap.subMarketOptions = this.buildMapSelectOptions(propertyPicklistOptions.submarket, 'Sub Market');
                        this.aptoMap.tenancyOptions = this.buildMapSelectOptions(propertyPicklistOptions.tenancy, 'Tenancy');
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyMapPage.prototype.initialize = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.setPicklistOptions()];
                    case 1:
                        _a.sent();
                        this.authService.onSetUser.subscribe(function (user) {
                            if (user) {
                                _this.aptoMap.setHistoryUniqueKey("" + user.id);
                            }
                        }, this.logsService.error);
                        this.aptoMap.initMap();
                        this.analyticsService.logEvent('Mapping Loaded');
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyMapPage.prototype.onMapReady = function () {
        var _this = this;
        this.isInitializingMap = false;
        this.aptoMap.listView.onUpdateWithMove.subscribe(function (updateWithMove) {
            _this.updateWithMove = updateWithMove;
            // turn off search here button if enabled
            if (_this.updateWithMove) {
                _this.aptoMap.searchHereEnabled = false;
            }
            _this.analyticsService.logEvent("Mapping Update On Map Move " + _this.updateWithMove);
        }, this.logsService.error);
        this.setupFilters();
        this.search(this.aptoMap.getFilterPanelQueries(), true);
    };
    PropertyMapPage.prototype.filterSaleDateVisible = function (query) {
        if (query.propertyStatus) {
            var propertyStatus = query.propertyStatus.value;
            var fltSaleDate = this.aptoMap.saleFilterPanel.filters.find(function (el) { return el.name === 'soldDate'; });
            var fltSaleDateNative = fltSaleDate.getElement().nativeElement;
            if (propertyStatus === 'forSale') {
                fltSaleDateNative.style = 'display: none';
            }
            if (propertyStatus === 'sold') {
                fltSaleDateNative.style = '';
            }
        }
    };
    PropertyMapPage.prototype.setupFilters = function () {
        var _this = this;
        this.aptoMap.onQueryChange.pipe(debounceTime(this.debounceTime)).subscribe(function (_a) {
            var query = _a.query;
            _this.filterSaleDateVisible(query);
            _this.search(query, true);
            var urlSafeQuery = _this.aptoMap.urlSafeQuery(query);
            _this.updateUrlBar(urlSafeQuery);
        }, this.logsService.error);
        var propertyTypeOptions = [];
        var propTypeNames = Object.keys(this.propertyTypes);
        propTypeNames.forEach(function (propTypeName) {
            propertyTypeOptions.push({
                label: propTypeName,
                value: _this.propertyTypes[propTypeName]
            });
        });
        var rawQuery = this.route.snapshot.paramMap.get('query');
        this.aptoMap.initFilters({
            propertyTypeOptions: propertyTypeOptions,
            initialQuery: this.aptoMap.decodeUrlSafeQuery(rawQuery)
        });
        var query = this.aptoMap.decodeUrlSafeQuery(rawQuery);
        this.filterSaleDateVisible(query);
    };
    PropertyMapPage.prototype.onMarkerClick = function (marker) {
        if (marker.type === MarkerType.PROPERTY) {
            var property = new PropertyModel(marker.data);
            this.analyticsService.logEvent('Mapping Click Property Marker');
            this.viewProperty(property);
        }
    };
    PropertyMapPage.prototype.onMarkerMouseOut = function (marker) {
        if (marker.type !== MarkerType.PLACE) {
            this.aptoMap.infoWindow.close();
        }
    };
    PropertyMapPage.prototype.onMarkerHover = function (marker) {
        return __awaiter(this, void 0, void 0, function () {
            var property;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isBusy()) {
                            // don't do anything if map is busy
                            return [2 /*return*/];
                        }
                        if (!(marker.type === MarkerType.PROPERTY))
                            return [3 /*break*/, 2];
                        property = new PropertyModel(marker.data);
                        this.aptoMap.infoWindow.show(PropertyInfoWindow, marker);
                        // if property is not in the current page of the list view append it to the bottom
                        if (!this.aptoMap.itemShown(property)) {
                            this.aptoMap.addShownItem(property);
                            this.ref.detectChanges();
                        }
                        // scroll to the property
                        this.scrollToProperty(property._id);
                        return [4 /*yield*/, this.getPropertyImage(property)];
                    case 1:
                        _a.sent();
                        // update the maker data with the new image data and tell views to detect changes
                        marker.data.blobImageData = property.blobImageData;
                        return [3 /*break*/, 3];
                    case 2:
                        if (marker.type === MarkerType.PLACE) {
                            this.aptoMap.infoWindow.show(PlaceInfoWindow, marker, null, { x: 20, y: 0 });
                            this.subscribeToPlaceInfoWindowEvents();
                        }
                        _a.label = 3;
                    case 3:
                        this.aptoMap.infoWindow.detectContentChanges();
                        this.ref.detectChanges();
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyMapPage.prototype.onMapBoundsChange = function (e) {
        if (this.stopMapZoomPanEvents) {
            return;
        }
        if (this.updateWithMove) {
            this.search(this.aptoMap.getFilterPanelQueries());
        }
        else {
            this.aptoMap.searchHereEnabled = true;
        }
    };
    PropertyMapPage.prototype.onSearchHere = function (e) {
        this.analyticsService.logEvent('Mapping Clicked Search Here');
        this.search(this.aptoMap.getFilterPanelQueries());
    };
    PropertyMapPage.prototype.onListItemClick = function (property) {
        this.analyticsService.logEvent('Mapping Click List Item Property');
        this.viewProperty(property);
    };
    PropertyMapPage.prototype.onListItemLeave = function (property) {
        this.aptoMap.infoWindow.close();
    };
    PropertyMapPage.prototype.onListItemHover = function (property) {
        return __awaiter(this, void 0, void 0, function () {
            var id, marker, clusterLatLng;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isBusy()) {
                            return [2 /*return*/]; // don't do anything if map is busy
                        }
                        id = "" + MarkerType.PROPERTY + property._id;
                        marker = this.aptoMap.getMarkerManager().markers.get(id);
                        // if marker does not exist return
                        if (!marker) {
                            return [2 /*return*/];
                        }
                        clusterLatLng = this.getMarkersClusterLatLng(marker);
                        this.aptoMap.infoWindow.show(PropertyInfoWindow, marker, clusterLatLng);
                        return [4 /*yield*/, this.getPropertyImage(property)];
                    case 1:
                        _a.sent();
                        // update the maker data with the new image data and tell the views to detect changes
                        marker.data.blobImageData = property.blobImageData;
                        this.aptoMap.infoWindow.detectContentChanges();
                        this.ref.detectChanges();
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyMapPage.prototype.onChangePage = function (properties) {
        this.getPropertyImages(properties);
    };
    PropertyMapPage.prototype.onAddPropertyClick = function (event) {
        var selectedProperty = new PropertyModel();
        if (event && event.place) {
            var addressObj = {};
            var streetNum = null;
            var streetName = null;
            if (event.place.addressComponents) {
                for (var _i = 0, _a = event.place.addressComponents; _i < _a.length; _i++) {
                    var field = _a[_i];
                    if (field.types.includes('street_number')) {
                        streetNum = field.long_name;
                    }
                    else if (field.types.includes('route')) {
                        streetName = field.long_name;
                    }
                    else if (field.types.includes('locality')) {
                        addressObj.city = field.long_name;
                    }
                    else if (field.types.includes('administrative_area_level_1')) {
                        addressObj.state = field.short_name;
                    }
                    else if (field.types.includes('administrative_area_level_2')) {
                        addressObj.county = field.long_name;
                    }
                    else if (field.types.includes('country')) {
                        addressObj.country = field.long_name;
                        addressObj.countryCode = field.short_name;
                    }
                    else if (field.types.includes('postal_code')) {
                        addressObj.postalCode = field.long_name;
                    }
                }
            }
            if (streetNum || streetName) {
                addressObj.street = ((streetNum || '') + " " + (streetName || '')).trim();
            }
            else {
                addressObj.street = event.place.addressStr;
            }
            selectedProperty.address = new AddressModel('Address', addressObj);
            selectedProperty.state = addressObj.state || null;
            selectedProperty.name = event.place.name || null;
        }
        var propertyDetailsModal = this.modalController.create(PropertyDetailsModal, {
            selectedProperty: selectedProperty,
            mode: PropertyDetailsModalMode.EDIT,
            types: Object.keys(this.propertyTypes),
            url: {
                params: {},
                baseHash: '#/property-map'
            }
        }, {
            cssClass: 'large-modal'
        });
        propertyDetailsModal.present({ updateUrl: false });
    };
    PropertyMapPage.prototype.onUpdatePropertyAddress = function (suggestion) {
        return __awaiter(this, void 0, void 0, function () {
            var street, address, prop, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        street = (suggestion.addressComponents.streetNumber || '') + " " + (suggestion.addressComponents.streetName || '');
                        address = new AddressModel('Address', {
                            street: street.trim(),
                            city: suggestion.addressComponents && suggestion.addressComponents.city,
                            state: suggestion.addressComponents &&
                                suggestion.addressComponents.administrativeLevels &&
                                suggestion.addressComponents.administrativeLevels.level1Long,
                            stateCode: suggestion.addressComponents &&
                                suggestion.addressComponents.administrativeLevels &&
                                suggestion.addressComponents.administrativeLevels.level1Short,
                            postalCode: suggestion.addressComponents && suggestion.addressComponents.zipCode,
                            county: suggestion.addressComponents &&
                                suggestion.addressComponents.administrativeLevels &&
                                suggestion.addressComponents.administrativeLevels.level2Long,
                            country: suggestion.addressComponents && suggestion.addressComponents.country,
                            countryCode: suggestion.addressComponents && suggestion.addressComponents.countryCode
                        });
                        prop = new PropertyModel({
                            _id: suggestion.propertyId || null,
                            address: address,
                            latitude: suggestion.latitude || null,
                            longitude: suggestion.longitude || null
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        if (!suggestion.propertyId)
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.propertiesService.upsert(prop)];
                    case 2:
                        _a.sent();
                        // run new search after update so views update
                        this.search(this.aptoMap.getFilterPanelQueries());
                        this.showToast('Property address has been updated');
                        this.analyticsService.logEvent('Mapping Updated Address Using Suggestion');
                        return [3 /*break*/, 4];
                    case 3:
                        this.showToast('Invalid Property');
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_3 = _a.sent();
                        this.logsService.error(err_3);
                        this.showToast('An Error occured updating the property address');
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PropertyMapPage.prototype.getPropertyFields = function () {
        return [
            'apn',
            'address_suggestions',
            'building_location',
            'city',
            'county',
            'country',
            'class',
            'description',
            'floor',
            'image_attachment_id',
            'land_acres',
            'land_sf',
            'last_sale_date',
            'last_sale_price',
            'latitude',
            'longitude',
            'market',
            'name',
            'number_of_stories',
            'occupancy',
            'parent_property',
            'parking_ratio2',
            'parking_spaces',
            'primary_use',
            'street_address',
            'property_type',
            'record_type',
            'sf_id',
            'space_property_type',
            'space_status',
            'space_sub_type',
            'square_footage',
            'state',
            'status',
            'submarket',
            'suite_floor',
            'tenancy',
            'units',
            'year_built',
            'year_renovated',
            'zip_code',
            'zoning'
        ];
    };
    PropertyMapPage.prototype.onAddSelectedToList = function (properties) {
        var modalParams = {
            propertyList: properties
        };
        var callListMembersModal = this.modalController.create(CallListMembersModal, modalParams, {
            cssClass: 'small-modal',
            enableBackdropDismiss: false
        });
        this.analyticsService.logEvent('Mapping Click Add To List');
        callListMembersModal.present({ updateUrl: false });
    };
    PropertyMapPage.prototype.onCallistMaxSelectionError = function (msg) {
        this.showToast(msg);
    };
    PropertyMapPage.prototype.viewProperty = function (property) {
        var propertyDetailsModal = this.modalController.create(PropertyDetailsModal, {
            selectedProperty: property,
            mode: PropertyDetailsModalMode.VIEW,
            url: {
                params: { propertyId: property._id },
                baseHash: '#/property-map'
            },
            types: Object.keys(this.propertyTypes)
        }, {
            cssClass: 'large-modal'
        });
        propertyDetailsModal.present({ updateUrl: false });
    };
    PropertyMapPage.prototype.updateUrlBar = function (query) {
        history.pushState(undefined, undefined, "/#/property-map/query/" + query);
    };
    PropertyMapPage.prototype.getOwnerData = function (propertyId, ownerships) {
        var result = {};
        var propertyOwnerships = ownerships.filter(function (el) { return el.propertyId === propertyId && el.contactRole === OwnershipModel.ROLE.OWNER; });
        if (ownerships.length > 0) {
            var contactRecord = propertyOwnerships.find(function (el) { return !!el.contact; });
            var companyRecord = propertyOwnerships.find(function (el) { return !el.contact && !!el.company; });
            var contact = contactRecord ? contactRecord.contact : undefined;
            var company = companyRecord ? companyRecord.company : contactRecord ? contactRecord.company : undefined;
            result.ownerName = contact ? contact.fullName : '';
            result.ownerCompany = company ? company.name : '';
            result.ownerPhone = this.extractOwnerPhone(contact, company);
            result.ownerEmail = this.extractOwnerEmail(contact);
        }
        return result;
    };
    PropertyMapPage.prototype.extractOwnerEmail = function (contact) {
        if (contact) {
            if (contact.email && contact.email.address) {
                return contact.email.address;
            }
            if (contact.email2 && contact.email2.address) {
                return contact.email2.address;
            }
        }
        return '';
    };
    PropertyMapPage.prototype.extractOwnerPhone = function (contact, company) {
        if (contact) {
            if (contact.phone && contact.phone.number) {
                return contact.phone.number;
            }
            if (contact.mobilePhone && contact.mobilePhone.number) {
                return contact.mobilePhone.number;
            }
            if (contact.homePhone && contact.homePhone.number) {
                return contact.homePhone.number;
            }
            if (contact.otherPhone && contact.otherPhone.number) {
                return contact.otherPhone.number;
            }
        }
        if (company) {
            if (company.phone && company.phone.number) {
                return company.phone.number;
            }
        }
        return '';
    };
    PropertyMapPage.prototype.onExportSelected = function (exportEvent) {
        return __awaiter(this, void 0, void 0, function () {
            var propertyIds, ownerships, data, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        this.exportService.startExportProcessing();
                        propertyIds = exportEvent.selected.map(function (el) { return el._id; });
                        return [4 /*yield*/, this.propertiesService.getOwnerships(propertyIds)];
                    case 1:
                        ownerships = _a.sent();
                        return [4 /*yield*/, this.ownershipRelationsService.attachCompanyOwners(ownerships)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.ownershipRelationsService.attachContactOwners(ownerships)];
                    case 3:
                        _a.sent();
                        data = this.getExportCreateRequest(exportEvent, ownerships);
                        return [4 /*yield*/, this.exportService.exportProperties(data)];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        err_4 = _a.sent();
                        this.logsService.error(err_4);
                        return [3 /*break*/, 6];
                    case 6:
                        this.analyticsService.logEvent("Mapping Exportorted " + exportEvent.selected.length + " properties as a " + exportEvent.type);
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyMapPage.prototype.formatSalePrice = function (property) {
        if (!isNaN(Number(property.salePrice))) {
            property.salePrice = "$" + parseInt(property.salePrice).toLocaleString('en-US');
        }
    };
    PropertyMapPage.prototype.formatSqrFootage = function (property) {
        if (!isNaN(Number(property.squareFootage))) {
            property.squareFootage = parseInt(property.squareFootage).toLocaleString('en-US');
        }
    };
    PropertyMapPage.prototype.getExportCreateRequest = function (exportEvent, ownerships) {
        var _this = this;
        var properties = exportEvent.selected;
        var propCount = exportEvent.selected.length + " propert" + (exportEvent.selected.length > 1 ? 'ies' : 'y');
        var timeStamp = moment().format('M/D/YYYY hh:mm:ss');
        var cleanProperties = properties.map(function (m) {
            var usePrettyKeys = exportEvent.type === 'csv';
            var obj = m.getForExport(usePrettyKeys);
            // translate record type id to pretty propertyType
            var prettyPropertyType = _this.mappingCollection.recordTypeMapping[m.recordTypeId] || null;
            if (usePrettyKeys) {
                var propertyTypeField = 'Property Type';
                obj[propertyTypeField] = prettyPropertyType;
            }
            else {
                obj.propertyType = prettyPropertyType;
            }
            _this.formatSalePrice(obj);
            _this.formatSqrFootage(obj);
            return Object.assign(obj, _this.getOwnerData(m._id, ownerships));
        });
        return {
            name: propCount + " " + timeStamp,
            type: exportEvent.type,
            data: {
                description: null,
                contactInfo: null,
                layout: exportEvent.layout,
                properties: cleanProperties
            }
        };
    };
    PropertyMapPage.prototype.search = function (updatedQuery, zoomOnComplete, forceSkipBoundingBox) {
        if (updatedQuery === void 0) {
            updatedQuery = {};
        }
        if (zoomOnComplete === void 0) {
            zoomOnComplete = false;
        }
        if (forceSkipBoundingBox === void 0) {
            forceSkipBoundingBox = false;
        }
        return __awaiter(this, void 0, void 0, function () {
            var queries_1, soldDate, yearBuilt, status_1, soldPrice, sqft, units, lotSize, leaseRate, textbox, zipCode, keyEx_1, city, county, keyEx_2, propertyType, keyEx_3, subMarket, keyEx_4, market, keyEx_5, buildingClass, keyEx_6, tenancy, keyEx_7, primaryUse, keyEx_8, state, keyEx_9, buildingStatus, keyEx_10, radius, polygon, hasBoundingBox, querystring, res, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.isBusy())
                            return [3 /*break*/, 5];
                        // remove search here button after running a search
                        this.aptoMap.searchHereEnabled = false;
                        // emit search start
                        this.aptoMap.onSearchStart.emit();
                        queries_1 = [
                            "perPage=" + this.resultLimit,
                            "orderBy=geom,IS NULL",
                            "orderBy=" + this.orderBy,
                            "parent_property__n=true" // don't query for space properties
                        ];
                        // translate the query to real search terms used by the mapping api
                        if (Object.keys(updatedQuery).length > 0) {
                            if (updatedQuery.soldDate) {
                                soldDate = updatedQuery.soldDate;
                                if (soldDate.startDate
                                    && soldDate.startDate.value) {
                                    queries_1.push("last_sale_date__gte=" + soldDate.startDate.value);
                                }
                                if (soldDate.stopDate && soldDate.stopDate.value) {
                                    queries_1.push("last_sale_date__lte=" + soldDate.stopDate.value);
                                }
                            }
                            if (updatedQuery.yearBuilt) {
                                yearBuilt = updatedQuery.yearBuilt;
                                if (yearBuilt.min && yearBuilt.min.value) {
                                    queries_1.push("year_built__gte=" + yearBuilt.min.value.slice(-4));
                                }
                                if (yearBuilt.max && yearBuilt.max.value) {
                                    queries_1.push("year_built__lte=" + yearBuilt.max.value.slice(-4));
                                }
                            }
                            if (updatedQuery.soldPrice) {
                                status_1 = updatedQuery.propertyStatus;
                                if (status_1.value === 'sold') {
                                    soldPrice = updatedQuery.soldPrice;
                                    if (soldPrice.min && soldPrice.min.value) {
                                        queries_1.push("last_sale_price__gte=" + soldPrice.min.value);
                                    }
                                    if (soldPrice.max && soldPrice.max.value) {
                                        queries_1.push("last_sale_price__lte=" + soldPrice.max.value);
                                    }
                                }
                            }
                            if (updatedQuery.sqft) {
                                sqft = updatedQuery.sqft;
                                if (sqft.min && sqft.min.value) {
                                    queries_1.push("square_footage__gte=" + sqft.min.value);
                                }
                                if (sqft.max && sqft.max.value) {
                                    queries_1.push("square_footage__lte=" + sqft.max.value);
                                }
                            }
                            if (updatedQuery.units) {
                                units = updatedQuery.units;
                                if (units.min && units.min.value) {
                                    queries_1.push("units__gte=" + units.min.value);
                                }
                                if (units.max && units.max.value) {
                                    queries_1.push("units__lte=" + units.max.value);
                                }
                            }
                            if (updatedQuery.lotSize) {
                                lotSize = updatedQuery.lotSize;
                                if (lotSize.min && lotSize.min.value) {
                                    queries_1.push("land_acres__gte=" + lotSize.min.value);
                                }
                                if (lotSize.max && lotSize.max.value) {
                                    queries_1.push("land_acres__lte=" + lotSize.max.value);
                                }
                            }
                            if (updatedQuery.leaseRate) {
                                leaseRate = updatedQuery.leaseRate;
                                if (leaseRate.min && leaseRate.min.value) {
                                    queries_1.push("lease_price__gte=" + leaseRate.min.value);
                                }
                                if (leaseRate.max && leaseRate.max.value) {
                                    queries_1.push("lease_price__lte=" + leaseRate.max.value);
                                }
                            }
                            textbox = updatedQuery.textbox;
                            if (textbox && textbox.value) {
                                queries_1.push("propertyFreetext=" + textbox.value);
                            }
                            zipCode = updatedQuery.zipCode;
                            if (zipCode && zipCode.value) {
                                if (Array.isArray(zipCode.value)) {
                                    keyEx_1 = zipCode.value.length > 1 ? '__in' : '';
                                    zipCode.value.forEach(function (v) {
                                        queries_1.push("zip_code" + keyEx_1 + "=" + v);
                                    });
                                }
                                else {
                                    queries_1.push("zip_code=" + zipCode.value);
                                }
                            }
                            city = updatedQuery.city;
                            if (city && city.value) {
                                queries_1.push("city__li=" + city.value);
                            }
                            county = updatedQuery.county;
                            if (county && county.value) {
                                if (Array.isArray(county.value)) {
                                    keyEx_2 = county.value.length > 1 ? '__in' : '';
                                    county.value.forEach(function (v) {
                                        queries_1.push("county" + keyEx_2 + "=" + v);
                                    });
                                }
                                else {
                                    queries_1.push("county=" + county.value);
                                }
                            }
                            propertyType = updatedQuery.propertyType;
                            if (propertyType && propertyType.value) {
                                keyEx_3 = propertyType.value.length > 1 ? '__in' : '';
                                propertyType.value.forEach(function (type) {
                                    queries_1.push("record_type" + keyEx_3 + "=" + type.value);
                                });
                            }
                            subMarket = updatedQuery.subMarket;
                            if (subMarket && subMarket.value) {
                                keyEx_4 = subMarket.value.length > 1 ? '__in' : '';
                                subMarket.value.forEach(function (type) {
                                    queries_1.push("submarket" + keyEx_4 + "=" + type.value);
                                });
                            }
                            market = updatedQuery.market;
                            if (market && market.value) {
                                keyEx_5 = market.value.length > 1 ? '__in' : '';
                                market.value.forEach(function (type) {
                                    queries_1.push("market" + keyEx_5 + "=" + type.value);
                                });
                            }
                            buildingClass = updatedQuery.buildingClass;
                            if (buildingClass && buildingClass.value) {
                                keyEx_6 = buildingClass.value.length > 1 ? '__in' : '';
                                buildingClass.value.forEach(function (type) {
                                    queries_1.push("class" + keyEx_6 + "=" + type.value);
                                });
                            }
                            tenancy = updatedQuery.tenancy;
                            if (tenancy && tenancy.value) {
                                keyEx_7 = tenancy.value.length > 1 ? '__in' : '';
                                tenancy.value.forEach(function (type) {
                                    queries_1.push("tenancy" + keyEx_7 + "=" + type.value);
                                });
                            }
                            primaryUse = updatedQuery.primaryUse;
                            if (primaryUse && primaryUse.value) {
                                keyEx_8 = primaryUse.value.length > 1 ? '__in' : '';
                                primaryUse.value.forEach(function (type) {
                                    queries_1.push("primary_use" + keyEx_8 + "=" + type.value);
                                });
                            }
                            state = updatedQuery.state;
                            if (state && state.value) {
                                keyEx_9 = state.value.length > 1 ? '__in' : '';
                                state.value.forEach(function (type) {
                                    queries_1.push("state" + keyEx_9 + "=" + type.value);
                                });
                            }
                            buildingStatus = updatedQuery.buildingStatus;
                            if (buildingStatus && buildingStatus.value) {
                                keyEx_10 = buildingStatus.value.length > 1 ? '__in' : '';
                                buildingStatus.value.forEach(function (type) {
                                    queries_1.push("status" + keyEx_10 + "=" + type.value);
                                });
                            }
                            radius = updatedQuery.Radius;
                            if (radius && radius.value) {
                                zoomOnComplete = false;
                                this.analyticsService.logEvent('Mapping Query Radius');
                                queries_1.push("radius=" + radius.value);
                            }
                            polygon = updatedQuery.Polygon;
                            if (polygon && polygon.value) {
                                zoomOnComplete = false;
                                this.analyticsService.logEvent('Mapping Query Polygon');
                                queries_1.push("polygon=" + polygon.value);
                            }
                        }
                        hasBoundingBox = false;
                        if (!forceSkipBoundingBox && this.shouldAddQueryBoundingBox(updatedQuery)) {
                            hasBoundingBox = true;
                            queries_1.push("bounds=" + this.aptoMap.getMapService().getBoundsAsWKT());
                        }
                        // limit what columns we get back from the api to make the request smaller
                        queries_1.push("columns=" + this.getPropertyFields().join(','));
                        this.toggleClearSearchButton(updatedQuery);
                        querystring = queries_1.join('&');
                        _a = this.filterResults;
                        return [4 /*yield*/, this.mappingCollection.fetch(querystring)];
                    case 1: return [4 /*yield*/, _a.apply(this, [_b.sent(), updatedQuery])];
                    case 2:
                        res = _b.sent();
                        this.properties = res.properties || [];
                        this.totalResults = res.total || 0;
                        // if we have a bounding box query with no results retry once without the bounding box and zoom to that set
                        if (zoomOnComplete &&
                            this.totalResults === 0 &&
                            this.enableSearchSecondAttempt &&
                            hasBoundingBox) {
                            this.enableSearchSecondAttempt = false;
                            return [2 /*return*/, this.search(updatedQuery, true, true)];
                        }
                        // reset attempts if we get this far
                        this.enableSearchSecondAttempt = true;
                        this.addMarkersFromResults();
                        if (!zoomOnComplete)
                            return [3 /*break*/, 4];
                        this.stopMapZoomPanEvents = true;
                        return [4 /*yield*/, this.aptoMap.zoomToFitMarkers(this.properties)];
                    case 3:
                        _b.sent();
                        this.stopMapZoomPanEvents = false;
                        _b.label = 4;
                    case 4:
                        // emit search end
                        this.aptoMap.onSearchEnd.emit();
                        // manually detect changes after search is done
                        this.ref.detectChanges();
                        _b.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PropertyMapPage.prototype.buildMapSelectOptions = function (options, defaultFieldLabel) {
        return Object.keys(options).sort().map(function (key) {
            return { label: key || "Any " + defaultFieldLabel, value: key ? options[key] : undefined };
        });
    };
    PropertyMapPage.prototype.subscribeToPlaceInfoWindowEvents = function () {
        var _this = this;
        // remove any existing events
        this.placeInfoWindowSubscriptions.forEach(function (e) {
            e.unsubscribe();
        });
        this.placeInfoWindowSubscriptions = [];
        // subscribe to close click for place infowindow
        this.placeInfoWindowSubscriptions.push(this.aptoMap.infoWindow.subscribe('onCloseClick', (function (e) {
            _this.aptoMap.infoWindow.close();
        }), this.logsService.error));
        // subscribe to search near place
        this.placeInfoWindowSubscriptions.push(this.aptoMap.infoWindow.subscribe('onSearchNearPlace', (function (latlng) {
            _this.aptoMap.radiusFilter.setInitialQuery({
                Radius: {
                    label: 'Radius',
                    value: latlng.lat() + "," + latlng.lng() + ",1"
                }
            });
            _this.ref.detectChanges();
            _this.aptoMap.infoWindow.close();
            _this.search(_this.aptoMap.getFilterPanelQueries());
        }), this.logsService.error));
        // subscribe to add place
        this.placeInfoWindowSubscriptions.push(this.aptoMap.infoWindow.subscribe('onAddPlace', (function (place) {
            _this.onAddPropertyClick({
                place: {
                    name: place.name,
                    addressStr: place.formatted_address,
                    addressComponents: place.address_components
                }
            });
            _this.aptoMap.infoWindow.close();
        }), this.logsService.error));
    };
    PropertyMapPage.prototype.isBusy = function () {
        if (this.aptoMap.disabled) {
            return true;
        }
        return false;
    };
    PropertyMapPage.prototype.getPropertyImage = function (property) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, err_5;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(property.defaultImageAttachmentId && !property.blobImageData))
                            return [3 /*break*/, 4];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = property;
                        return [4 /*yield*/, this.propertiesService.fetchImageData(property.defaultImageAttachmentId)];
                    case 2:
                        _a.blobImageData = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_5 = _b.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PropertyMapPage.prototype.getPropertyImages = function (properties) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var promises;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promises = properties.map(function (prop) { return _this.getPropertyImage(prop); });
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyMapPage.prototype.getMarkersClusterLatLng = function (marker) {
        if (!marker) {
            return null;
        }
        var clusterer = this.aptoMap.getMarkerManager().markerClusterer;
        var aptoId = marker.getId();
        var point = null;
        if (clusterer && clusterer.clusters_ && clusterer.clusters_.length) {
            for (var _i = 0, _a = clusterer.clusters_; _i < _a.length; _i++) {
                var cluster = _a[_i];
                if (cluster.markers_ && cluster.markers_.length) {
                    for (var _b = 0, _c = cluster.markers_; _b < _c.length; _b++) {
                        var m = _c[_b];
                        if (m.markerId && m.markerId === aptoId) {
                            point = cluster.center_;
                            break;
                        }
                    }
                }
                if (point) {
                    break;
                }
            }
        }
        return point;
    };
    /*
     * method to filter properties on the client side because certain fields are not available in our
     * property database
     */
    PropertyMapPage.prototype.filterResults = function (results, filters) {
        if (filters === void 0) {
            filters = {};
        }
        return __awaiter(this, void 0, void 0, function () {
            var properties, status, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        status = filters.propertyStatus && filters.propertyStatus.value ? filters.propertyStatus.value : 'all';
                        _a = status;
                        switch (_a) {
                            case 'forSale': return [3 /*break*/, 1];
                            case 'forLease': return [3 /*break*/, 2];
                            case 'sold': return [3 /*break*/, 3];
                            case 'leased': return [3 /*break*/, 5];
                        }
                        return [3 /*break*/, 7];
                    case 1:
                        this.analyticsService.logEvent('Mapping Query For Sale');
                        properties = this.mappingDataManager.filterForSale(results.properties, filters.capRate, filters.soldPrice);
                        return [2 /*return*/, {
                                properties: properties,
                                total: properties.length
                            }];
                    case 2:
                        this.analyticsService.logEvent('Mapping Query For Lease');
                        properties = this.mappingDataManager.filterForLease(results.properties);
                        return [2 /*return*/, {
                                properties: properties,
                                total: properties.length
                            }];
                    case 3:
                        this.analyticsService.logEvent('Mapping Query For Sold');
                        return [4 /*yield*/, this.mappingDataManager.filterSold(results.properties, filters.capRate)];
                    case 4:
                        properties = _b.sent();
                        return [2 /*return*/, {
                                properties: properties,
                                total: properties.length
                            }];
                    case 5:
                        this.analyticsService.logEvent('Mapping Query For Leased');
                        return [4 /*yield*/, this.mappingDataManager.filterLeased(results.properties)];
                    case 6:
                        properties = _b.sent();
                        return [2 /*return*/, {
                                properties: properties,
                                total: properties.length
                            }];
                    case 7:
                        // query for solds so we can show the correct markers on the map
                        return [4 /*yield*/, this.mappingDataManager.getCompsByPropertyIds(results.properties.map(function (p) { return p._id; }))];
                    case 8:
                        // query for solds so we can show the correct markers on the map
                        _b.sent();
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/, results];
                }
            });
        });
    };
    // todo: unit test for this
    PropertyMapPage.prototype.toggleClearSearchButton = function (usedFilters) {
        if (usedFilters === void 0) {
            usedFilters = {};
        }
        var unClearableFilters = [
            'Radius',
            'Polygon'
        ];
        var visible = false;
        for (var _i = 0, _a = Object.keys(usedFilters); _i < _a.length; _i++) {
            var filter = _a[_i];
            if (filter === 'propertyStatus' && usedFilters.propertyStatus.value === 'all') {
                continue;
            }
            if (unClearableFilters.includes(filter)) {
                continue;
            }
            visible = true;
            break;
        }
        this.aptoMap.clearFiltersEnabled = visible;
    };
    PropertyMapPage.prototype.addMarkersFromResults = function () {
        var _this = this;
        var markers = this.properties.map(function (p) {
            var iconClass = null;
            var listing = _this.mappingDataManager.getListingFromCacheByPropertyId(p._id);
            var soldOrLeased = _this.mappingDataManager.getSoldFromCacheByPropertyId(p._id);
            if (listing) {
                p.forSale = !(listing.dateOffMarket || listing.onMarketStatus === ListingModel.STATUSES.IN_CONTRACT);
                ;
                p.daysOnMarket = !listing.dateOffMarket && listing.daysOnMarket && listing.daysOnMarket > 0 ? listing.daysOnMarket : null;
                p.listingId = listing._id;
                p.internal = _this.mappingDataManager.isInternalListing(listing, _this.listingTypes);
                iconClass = p.internal ? MarkerClassName.ON_MARKET_INTERNAL : MarkerClassName.ON_MARKET;
            }
            else if (soldOrLeased.length && _this.mappingDataManager.isRecentlySold(p)) {
                p.recentlySold = true;
                p.internal = _this.mappingDataManager.isInternalComp(soldOrLeased, _this.compTypes);
                iconClass = p.internal ? MarkerClassName.OFF_MARKET_INTERNAL : MarkerClassName.OFF_MARKET;
            }
            return {
                id: p._id,
                latitude: p.latitude,
                longitude: p.longitude,
                iconClass: iconClass,
                data: p
            };
        });
        this.aptoMap.addMarkers(markers, MarkerType.PROPERTY);
    };
    PropertyMapPage.prototype.showToast = function (message) {
        var toast = this.toastController.create({
            duration: TOAST_DURATION,
            message: message,
            showCloseButton: true
        });
        toast.present();
    };
    // determine if any of the filters should cause us to ignore the bounding box
    PropertyMapPage.prototype.shouldAddQueryBoundingBox = function (q) {
        var useBoundingBox = true;
        var locationBasedSearches = [
            'textbox',
            'city',
            'state',
            'zipCode',
            'market',
            'county',
            'Radius',
            'Polygon'
        ];
        locationBasedSearches.forEach(function (locationSearch) {
            if (Object.keys(q).includes(locationSearch)) {
                useBoundingBox = false;
            }
        });
        return useBoundingBox;
    };
    PropertyMapPage.prototype.scrollToProperty = function (id) {
        var prop = document.getElementById("apto-map--list-view-item--property-" + id);
        if (prop) {
            prop.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
            });
        }
    };
    return PropertyMapPage;
}(BaseListPage));
export { PropertyMapPage };
