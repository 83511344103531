<ion-card class="property-additional-info-card">
    <ion-card-header>Additional information</ion-card-header>

    <ion-card-content>
        <div *ngIf="property.parcelNumber">
            <ion-label stacked>Parcel number</ion-label>
            <p class="dark-text">{{property.parcelNumber}}</p>
        </div>

        <div *ngIf="property.occupancy">
            <ion-label stacked>Occupancy (%)</ion-label>
            <p class="dark-text">{{property.occupancy}}</p>
        </div>

        <div *ngIf="property.type === 'Multifamily' && property.squareFootage">
            <ion-label stacked>Square footage</ion-label>
            <p class="dark-text">{{property.squareFootage}}</p>
        </div>

        <div *ngIf="property.type !== 'Multifamily' && property.units">
            <ion-label stacked>Units</ion-label>
            <p class="dark-text">{{property.units}}</p>
        </div>

        <div *ngIf="property.tenancy">
            <ion-label stacked>Tenancy</ion-label>
            <p class="dark-text">{{property.tenancy}}</p>
        </div>

        <div *ngIf="property.status">
            <ion-label stacked>Building status</ion-label>
            <p class="dark-text">{{property.status}}</p>
        </div>

        <div *ngIf="property.zoning">
            <ion-label stacked>Zoning</ion-label>
            <p class="dark-text">{{property.zoning}}</p>
        </div>

        <div *ngIf="property.parkingSpaces !== undefined">
            <ion-label stacked>Parking spaces</ion-label>
            <p class="dark-text">{{property.parkingSpaces}}</p>
        </div>

        <!-- <div *ngIf="property.parkingRatio">
            <ion-label stacked>Parking ratio</ion-label>
            <p class="dark-text">{{property.parkingRatio}}</p>
        </div> -->
    </ion-card-content>
</ion-card>
